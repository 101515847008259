import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {Navigate, Routes} from 'react-router-dom'
import {LOGIN_RESET} from '../../store/constants/LoginConstants'

export function Logout() {
  const dispatch = useDispatch()
  useEffect(() => {
    localStorage.setItem('token', '')

    dispatch({type: LOGIN_RESET})
    document.location.reload()
  }, [dispatch])

  return (
    <Routes>
      <Navigate to='/auth/login' />
    </Routes>
  )
}
