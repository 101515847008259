import {createRoot} from 'react-dom/client'
import {Provider} from 'react-redux'
// Axios
import axios from 'axios'
import {Chart, registerables} from 'chart.js'
import {QueryClient, QueryClientProvider} from 'react-query'
import {ReactQueryDevtools} from 'react-query/devtools'
// Apps
// import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n'
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
// import './_metronic/assets/css/style.rtl.css'
// import './_metronic/assets/sass/style.scss'
// import './_metronic/assets/sass/plugins.scss'

// import './_metronic/assets/sass/style.react.scss'
// import {AppRoutes} from './app/routing/AppRoutes'
import {AuthProvider, setupAxios} from './app/modules/auth'
import {persistor, store} from './app/store/store'
import {PersistGate} from 'redux-persist/integration/react'
// import {ApolloClient, InMemoryCache, ApolloProvider} from '@apollo/client'
import React, {Suspense} from 'react'
import './styles.css'

const Theme1 = React.lazy(() => import('./Theme1'))
const Theme2 = React.lazy(() => import('./Theme2'))

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
setupAxios(axios)
Chart.register(...registerables)

const getRtl = localStorage.getItem('rtl')

const queryClient = new QueryClient()

if (getRtl === 'true') {
  document.getElementById('html')?.setAttribute('direction', 'rtl')
  document.getElementById('html')?.setAttribute('dir', 'rtl')
  document.getElementById('html')?.setAttribute('style', 'direction: rtl')
} else {
  document.getElementById('html')?.removeAttribute('direction')
  document.getElementById('html')?.removeAttribute('dir')
  document.getElementById('html')?.removeAttribute('style')
}

const container = document.getElementById('root')
if (container) {
  createRoot(container).render(
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <Suspense fallback={<></>}>{getRtl === 'true' ? <Theme2 /> : <Theme1 />}</Suspense>
          {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  )
}
