import {ALL_SPACES, SINGLE_SPACES} from '../constants/spaceConstants'

interface State {
  allSpaces: any
  singleSpace: any
}

const initialState: State = {
  allSpaces: [],
  singleSpace: {},
}

export const spaceReducer = (state: State = {...initialState}, action: any): State => {
  switch (action?.type) {
    case ALL_SPACES:
      return {
        ...state,
        allSpaces: action.payload,
      }

    case SINGLE_SPACES:
      return {
        ...state,
        singleSpace: action.payload,
      }
    default:
      return state
  }
}
