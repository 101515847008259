import {LOGIN_SUCCESS} from '../constants/LoginConstants'

interface SuccessObj {
  type: string
  payload: any
}

export const Login_success = (payload: any): SuccessObj => ({
  type: LOGIN_SUCCESS,
  payload,
})
