import {
  SHOW_ADAPTIVE_PAGES,
  SHOW_PILLAR_PAGES,
  SHOW_COLLABORATION,
  SHOW_SITEMAP,
  ALL_ADAPTIVE_PAGES,
  ALL_PILLAR_PAGES,
  SELECTED_SECTION,
  SHOW_GLOBAL_SECTIONS,
  SHOW_ALL_COMMENTS,
} from '../constants/generativeStudioConstants'

interface State {
  showCollaboration: any
  showAdaptivePages: any
  showPillarPages: any
  showSiteMap: any
  showGlobalNodes: any
  showAllComments: any
  allAdaptivePagesR: any
  allPillarPagesR: any
  selectedSectionR: any
}

const initialState: State = {
  showCollaboration: false,
  showAdaptivePages: false,
  showPillarPages: false,
  showSiteMap: false,
  showGlobalNodes: false,
  showAllComments: false,
  allAdaptivePagesR: null,
  allPillarPagesR: null,
  selectedSectionR: {
    name: 'main',
    id: 'main',
  },
}

export const generativeStudioReducer = (state: State = {...initialState}, action: any): State => {
  switch (action?.type) {
    case SHOW_COLLABORATION:
      return {
        ...state,
        showCollaboration: action.payload,
      }

    case SHOW_ADAPTIVE_PAGES:
      return {
        ...state,
        showAdaptivePages: action.payload,
      }

    case SHOW_PILLAR_PAGES:
      return {
        ...state,
        showPillarPages: action.payload,
      }

    case SHOW_SITEMAP:
      return {
        ...state,
        showSiteMap: action.payload,
      }

    case SHOW_GLOBAL_SECTIONS:
      return {
        ...state,
        showGlobalNodes: action.payload,
      }

    case SHOW_ALL_COMMENTS:
      return {
        ...state,
        showAllComments: action.payload,
      }

    case ALL_ADAPTIVE_PAGES:
      return {
        ...state,
        allAdaptivePagesR: action.payload,
      }

    case ALL_PILLAR_PAGES:
      return {
        ...state,
        allPillarPagesR: action.payload,
      }

    case SELECTED_SECTION:
      return {
        ...state,
        selectedSectionR: action.payload,
      }

    default:
      return state
  }
}
